import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { NextPage } from "next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Image from "next/image";
import BrokenLink from "../public/broken_link.png";

export default dynamic(() => Promise.resolve(NotFoundPage), {
  ssr: false,
});

export const NotFoundPage: NextPage = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const router = useRouter();

  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      <Box display="flex" minHeight={120} mb={2}>
        <Image
          src={BrokenLink}
          objectFit="fill"
          alt={"BrokenLink"}
          width={120}
          height={120}
          layout="fixed"
        />
      </Box>
      <Typography fontSize={24} fontWeight={700} color={colors.grey[500]}>
        404 Error
      </Typography>
      <Typography fontSize={48} fontWeight={700} color={colors.grey[700]}>
        Page Not Found
      </Typography>
      <Typography fontSize={16} fontWeight={400} color={colors.grey[600]}>
        Sorry, the page you&apos;re looking for doesn&apos;t exist.
      </Typography>
      <BackButton onClick={() => router.push("/home")}>Go Back</BackButton>
    </Stack>
  );
};

const BackButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[700]}`,
  color: "white",
  textTransform: "none",
  fontWeight: 400,
  marginTop: 15,
  minWidth: 120,
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: theme.palette.grey[800],
    fontWeight: 500,
  },
}));
